<template>
  <li class="nav-item active">
    <router-link class="nav-link" :to="to" @click="handleRouteClick">
      <div v-if="icon">
        <i class="material-icons-round opacity-10">{{ icon }}</i>
      </div>
      <span v-else class="sidenav-mini-icon text-white"> {{ miniIcon }} </span>
      <span class="sidenav-normal me-3 ms-3 ps-1 text-white text-white">
        {{ text }}
      </span>
    </router-link>
  </li>
</template>
<script>
import { mapMutations } from "vuex";

export default {
  name: "SidenavItem",
  props: {
    to: {
      type: [Object, String],
      required: true
    },
    miniIcon: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      required: false
    },
    text: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    handleRouteClick() {
      this.navbarMinimize();
    }
  }
};
</script>
