<template>
  <div
    id="sidenav-collapse-main"
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
  >
    <ul class="navbar-nav">
      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="brooklynAlice"
          :nav-text="name"
          :class="getRoute() === 'brooklynAlice' ? 'active' : ''"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">account_circle</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->
              <sidenav-item
                :to="{ name: 'New User' }"
                icon="settings_suggest"
                text="Onboarding"
              />
              <sidenav-item
                :to="{ name: 'Logout' }"
                icon="logout"
                text="Logout"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <hr class="horizontal light mt-2 mb-2" />
      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-item
          :to="{ name: 'NutritionOverview' }"
          icon="analytics"
          text="Dashboard"
        />
      </li>

      <sidenav-item
          :to="{ name: 'NutritionLog' }"
          icon="book"
          text="Daily Entry"
        />
    
      <hr class="horizontal light mt-2 mb-2" />
      <li class="nav-item" :class="getRoute() === 'nutrition' ? 'active' : ''">
        <sidenav-item
          :to="{ name: 'Macro Calculator' }"
          icon="pie_chart"
          text="Macro Calculator"
        />
      </li>
      <sidenav-item :to="{ name: 'Add Recipe' }" icon="add" text="Add Recipe" />
      <!-- commenting this out for now.-->
      <!-- <sidenav-item
        :to="{ name: 'Assistant' }"
        icon="help"
        text="Macro Assistant"
      /> -->

      <hr class="horizontal light mt-2 mb-2" />
      <ul class="nav pe-0">

        <sidenav-item
          :to="{ name: 'CheckIn' }"
          icon="done"
          :text="checkinText"
        />

        <sidenav-item
          :to="{ name: 'Plan' }"
          icon="trending_down"
          text="Start A New Plan"
        />

        <li class="nav-item" :class="getRoute() === 'booking' ? 'active' : ''">
        <sidenav-item
          :to="{ name: 'Bookings' }"
          icon="calendar_month"
          text="Bookings"
        />
        </li>

        <!-- commenting this out for now.-->
        <!-- <sidenav-item :to="{ name: 'Chat' }" icon="chat" text="Chat" /> -->
      </ul>
    </ul>
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import { mapState } from "vuex";
export default {
  name: "SidenavList",
  components: {
    SidenavItem,
    SidenavCollapse,
  },
  data() {
    return {
      title: "Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active",
    };
  },
  computed: {
    ...mapState(["isRTL"]),
    ...mapState(["name"]),
    isTodayCheckInDay() {
      //return true if sunday, monday or tuesday, otherwise false.

      const today = new Date();
      const day = today.getDay();

      if (day === 0 || day === 1 || day === 2) {
        return true;
      } else {
        return false;
      }
    },
    checkinText() {
      //if today is sunday, monday or tuesday, make the checkin button available
      const today = new Date();
      const day = today.getDay();

      if (day == 0 || day == 1 || day == 2) {
        return "Check In Now";
      }

      //calculate how many days until sunday

      const daysUntilSunday = 7 - day;

      return "Available in " + daysUntilSunday + " days";
    },
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
};
</script>
