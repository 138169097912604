<template>
  <div>
    <material-button
        color="info"
        size="sm"
        class="mt-2"
        :full-width="true"
        title="Click to analyze the nutritional label of an image"
        @click="showModal = true"
    > <font-awesome-icon :icon="['fas', 'camera']" />
      Capture Label
    </material-button>
    
    <div v-if="showModal" class="modal fade show" tabindex="-1" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Capture Label</h5>
            <material-button 
                type="button"
                variant="outline" 
                aria-label="Close"
                @click="closeModal" 
            ><font-awesome-icon :icon="['fas', 'x']" /></material-button>
          </div>
          <div class="modal-header">
            <div>Take a photo of a nutritional label and let us analyse the information and add it to your database.</div>
            <img src="../assets/images/examples/nutritionexample.png" alt="Nutrition Example" style="width: 100%; max-width: 300px; margin: 10px auto; display: block;"/>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitForm">
              <div class="mb-3 d-flex flex-column">
                <label for="file" class="form-label">Capture Image</label>
                <input id="file" required type="file" class="form-control styled-file-input" accept="image/*" capture="camera" @change="handleFileChange">
              </div>
              <div class="mb-3 d-flex flex-column">
                <label for="description" class="form-label">Description (optional)</label>
                <material-input id="description" v-model="description" type="text"></material-input>
              </div>
              <div>
                <div class="d-flex align-items-center justify-content-center">
                  <div v-if="isLoading" class="spinner-border mt-2 mb-3" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <material-button type="submit" class="btn btn-info" :disabled="isLoading" :full-width="true">Analyse Label</material-button>
              </div>              
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import apiClient from "@/service/apiClient";
// import Nutrition from "@/data/nutrition/nutrition";
import {logError} from "@/errors/rollbar";
import DateUtils from "@/common/dateutils";
import MaterialInput from "./MaterialInput.vue";

export default {
  name: "FileUploadComponent",
  components: {MaterialInput, MaterialButton},
  emits: ["close", "item-added"],
  data() {
    return {
      showModal: false,
      file: null,
      description: "",
      isLoading: false,
      analysisResult: null,
      day: {
        id: "",
        date: DateUtils.formatLocalDate(),
      },
    };
  },
  methods: {
    handleFileChange(event) {
      this.file = event.target.files[0];
      console.log("File:", this.file);
    },
    closeModal() {
      this.showModal = false;
      this.file = null;
      this.description = "";
      this.analysisResult = null;
      this.$emit("close");
    },
    async submitForm() {
      if (this.file && this.description) {
        this.isLoading = true;
        const formData = new FormData();
        formData.append('file', this.file);
        
        const errorText = "An error occurred while processing your image. Please try again later.";

        try {
          const response = await apiClient.post('/api/food-nutrition', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          
          let responseItem = null; 
          
          if (response.data.error) {
            this.$swal.fire({
              icon: 'error',
              title: 'An error occured',
              text: errorText
            });
            logError("Error processing image:", response.data.error);
            return;
          }
          
          try {
            responseItem = JSON.parse(response.data.analysis);
          } catch (error) {            
            if (response.data.analysis) {
              this.$swal.fire({
                icon: 'error',
                title: 'An error occured',
                text: errorText
              });
              logError("Error parsing JSON:", response.data.analysis);
            }
          }
          
          console.log("Response Item:", responseItem);
          
          let item = null;

          if (responseItem && responseItem.length > 0) {
            item = {
              food: this.description ? this.description : responseItem[0].food,
              protein: responseItem[0].protein,
              carbs: responseItem[0].carbs,
              fats: responseItem[0].fats,
              calories: responseItem[0].calories,
              weight: responseItem[0].weight,
              type: responseItem[0].type,
              date: this.day.date,
            };
          }

            /*const tableHTML = `
            <table style="margin-left: auto;margin-right: auto;">
              <thead>
                <tr>
                  <th>${item.food}</th>
                </tr>
              </thead>
              <tbody style="display: inline-table;margin-left: auto;margin-right: auto;">
                <br/>
                <tr>
                  <td>Protein</td>
                  <td>${item.protein}g</td>
                </tr>
                <tr>
                  <td>Carbs</td>
                  <td>${item.carbs}g</td>
                </tr>
                <tr>
                  <td>Fats</td>
                  <td>${item.fats}g</td>
                </tr>
              </tbody>
            </table>
          `;
            
            const confirm = await this.$swal.fire({
              title: "Looks okay?",
              html: `You will be adding these items to your database: <br> ${tableHTML}`,
              showCancelButton: true,
              confirmButtonText: "Yes, add them!",
              cancelButtonText: "I'll try again",
            });

            if (!confirm.isConfirmed) {
              this.isSearchLoading = false;
              return;
            }          
              
            const { error: diaryError } = await Nutrition.insertDiaryItem(item);
            const { error: databaseError } = await Nutrition.insertFood(item);

            if (diaryError || databaseError) {              
              this.$swal.fire({
                icon: 'error',
                title: 'An error occured',
                text: errorText
              });              
              logError("Error inserting data:", diaryError, databaseError);
            }*/
            this.$emit("item-added", item);  
          
            this.closeModal();            
                   
        } catch (error) {
          logError("Error uploading file:", error);
          this.$swal.fire({
            icon: 'error',
            title: 'Error Uploading File',
            text: `An error occurred while uploading the file. ${error}`
          });
        } finally {
          this.isLoading = false;
        }
      }
    }
  }
};
</script>

<style scoped>
.modal {
  background: rgba(0, 0, 0, 0.5);
}

.styled-file-input {
  border: 2px solid lightgrey;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  color: #333;
  background-color: #f8f9fa;
}

.styled-file-input:hover {
  border-color: #0056b3;
}

.styled-file-input:focus {
  outline: none;
  border-color: #0056b3;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

</style>