import supabase from "../createClient.js";
import UserSettings from "../user/userSettings.js";
import { logError } from "../../errors/rollbar.js";
import apiClient from "../../service/apiClient.js";

async function getLatestFoodAssistItem() {
  const { data, error } = await supabase
    .from("ai_responses")
    .select("id, created_at, response_text")
    .order("id", { ascending: false })
    .limit(1);

  if (error) {
    logError(error);
    return false;
  } else {
    return data[0];
  }
}

export async function foodAssist() {
  const latestResponse = await getLatestFoodAssistItem();

  if (latestResponse && latestResponse.response_text) {
    const lastResponseDate = new Date(latestResponse.created_at);
    const now = new Date();
    const diff = Math.abs(now - lastResponseDate);
    const minutes = Math.floor(diff / 1000 / 60);
    if (minutes < 60) {
      return latestResponse.response_text;
    }
  }

  const todaysDate = new Date().toISOString().slice(0, 10);

  const { data: userMetrics, error } = await supabase
    .from("usermetrics")
    .select("id, calories, protein, carbs, fats,userid")
    .eq("date", todaysDate)
    .limit(1);

  if (userMetrics.length === 0 || error) {
    if (error) {
      logError(error);
    }
    return "Please complete the onboarding guide to set your goal macros.";
  }

  const userId = await UserSettings.getUserId();

  const {
    data: nutritionDetails,
    error: nutritionDetailsError,
  } = await supabase
    .from("users")
    .select("nutritiondetailid")
    .eq("id", userId)
    .limit(1);

  if (nutritionDetailsError) {
    console.log(nutritionDetailsError);
    return "Please complete the onboarding guide to set your goal macros.";
  }

  const {
    data: goalNutirtionDetails,
    error: goalNutritionDetailsError,
  } = await supabase
    .from("nutritiondetails")
    .select("calories, protein, carbs, fats")
    .eq("id", nutritionDetails[0].nutritiondetailid)
    .limit(1);

  if (goalNutirtionDetails.length === 0 || goalNutritionDetailsError) {
    console.log(goalNutritionDetailsError);
    return "Keep logging your food and I'll be able to help you out soon!";
  }

  const goalCalories = goalNutirtionDetails[0].calories;
  const goalProtein = goalNutirtionDetails[0].protein;
  const goalCarbs = goalNutirtionDetails[0].carbs;
  const goalFats = goalNutirtionDetails[0].fats;

  const remainingCalories = goalCalories - userMetrics[0].calories;
  const remainingProtein = goalProtein - userMetrics[0].protein;
  const remainingCarbs = goalCarbs - userMetrics[0].carbs;
  const remainingFats = goalFats - userMetrics[0].fats;

  const body = {
    calories: remainingCalories,
    protein: remainingProtein,
    carbs: remainingCarbs,
    fats: remainingFats,
  };

  let response = null;

  try {
    response = await apiClient.post("/api/food-assist", body);

  } catch (error) {
    logError(error);
    return "Keep logging your food and I'll be able to help you out soon!";
  }

  const { error: insertError } = await supabase
    .from("ai_responses")
    .insert([{ response_text: response.data.message }]);

  if (insertError) {
    logError(insertError);
  }

  return response.data.message;
}
