<template>
  <aside
    id="sidenav-main"
    ref="sidebar"
    class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 ms-3"
    :class="`${
      isRTL ? 'me-3 rotate-caret fixed-end' : 'fixed-start ms-3'
    } ${sidebarType}`"
  >
    <div class="sidenav-header">
      <div class="position-fixed top-0 end-0 p-3">
        <div class="text-light font-weight-bold fs-4" @click="minimise">X</div>
      </div>
      <i
        id="iconSidenav"
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
      ></i>
      <router-link class="m-0 navbar-brand" to="/">
        <img :src="logo" class="navbar-brand-img h-100" alt="main_logo" />
        <span class="ms-1 font-weight-bold text-white">NutriCoachMe</span>
      </router-link>
    </div>
    <hr class="horizontal light mt-0 mb-2" />
    <sidenav-list />
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/images/square-logo-transparent.png";
import { mapMutations, mapState } from "vuex";
import { logError } from "../../errors/rollbar.js";
export default {
  name: "Index",
  components: {
    SidenavList,
  },
  data() {
    return {
      logo,
    };
  },
  computed: {
    ...mapState(["isRTL", "sidebarType", "isDarkMode", "isPinned"]),
  },
  mounted() {
    document.addEventListener("click", this.handleDocumentClick);
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    handleDocumentClick(event) {
      const sidebarElement = this.$refs.sidebar;
      const targetId = event.target.id;
      const validIds = [
        "iconNavbarSidenav",
        "divNavbarSidenav",
        "iSideBarNav1",
        "iSideBarNav2",
        "iSideBarNav3",
      ];

      if (!validIds.includes(targetId) && sidebarElement) {
        if (!sidebarElement.contains(event.target) && !this.isPinned) {
          try {
            this.navbarMinimize();
          } catch (error) {
            logError(error);
          }
        }
      }
    },
    minimise() {
      this.navbarMinimize();
    },
  },
};
</script>
