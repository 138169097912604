import supabase from "../createClient";
import { logError } from "../../errors/rollbar.js";
import { getCurrentPlan, createNewPlan } from "../plan/plan.js";
import UserSettings from "../user/userSettings.js";
import { getTodaysDate } from "../../helpers/dateUtils.js";
import apiClient from "../../service/apiClient.js";

class Nutrition {
  constructor() {
    this.supabase = supabase;
  }

  async saveMacros(
    userId,
    calories,
    protein,
    carbs,
    fats,
    dietaryPreference,
    goal,
    startingWeight,
    tdee = null
  ) {
    try {
      //if any nutritiondetails exists that don't have an end date, then set the end date to today.
      const { data: details, error: detailsError } = await this.supabase
        .from("nutritiondetails")
        .select()
        .is("datecomplete", null);

      if (detailsError) {
        logError(detailsError);
        throw new Error(
          `Error getting nutritiondetails: ${detailsError.message}`
        );
      }

      if (details.length > 0) {
        for (let i = 0; i < details.length; i++) {
          const detailId = details[i].id;

          const dateEnd = getTodaysDate();

          const { error: detailsError } = await this.supabase
            .from("nutritiondetails")
            .update({
              datecomplete: dateEnd,
            })
            .eq("id", detailId);

          if (detailsError) {
            logError(detailsError);
            throw new Error(
              `Error updating nutritiondetails: ${detailsError.message}`
            );
          }
        }
      }

      //save the new macros in a new nutritiondetails record
      const dateStart = getTodaysDate();

      const { data: detail, error: detailError } = await this.supabase
        .from("nutritiondetails")
        .insert({
          datestart: dateStart,
          calories: parseInt(calories),
          protein: parseInt(protein),
          carbs: parseInt(carbs),
          fats: parseInt(fats),
          dietary_preference: dietaryPreference,
          goal: goal ? goal : 0,
        })
        .select();

      if (detailError) {
        logError(detailError);
        throw new Error(
          `Error inserting nutritiondetails: ${detailError.message}`
        );
      }

      //update the users table with the new nutritiondetailid
      if (detail.length > 0) {
        const nutritionDetailId = detail[0].id;

        const { error: usersError } = await this.supabase
          .from("users")
          .update({
            nutritiondetailid: nutritionDetailId,
          })
          .eq("id", userId);

        if (usersError) {
          logError(detailError);
          throw new Error(`Error updating users: ${usersError.message}`);
        }
      } else {
        logError("No nutritiondetails inserted.");
        throw new Error("No nutritiondetails inserted.");
      }

      //update any previous tdeedetails with a null date_end to today.
      const { data: tdees, error: tdeesError } = await this.supabase
        .from("tdeedetails")
        .select()
        .is("date_end", null);

      if (tdeesError) {
        logError(tdeesError);
        throw new Error(`Error getting tdeedetails: ${tdeesError.message}`);
      }

      if (tdees.length > 0) {
        for (let i = 0; i < tdees.length; i++) {
          const tdeeId = tdees[i].id;

          const dateEnd = getTodaysDate();

          const { error: tdeesError } = await this.supabase
            .from("tdeedetails")
            .update({
              date_end: dateEnd,
            })
            .eq("id", tdeeId);

          if (tdeesError) {
            logError(tdeesError);
            throw new Error(
              `Error updating tdeedetails: ${tdeesError.message}`
            );
          }
        }
      }

      //insert a new tdee record for the user that starts today.
      const { error: tdeeError } = await this.supabase
        .from("tdeedetails")
        .insert({
          user_id: userId,
          date_start: dateStart,
          average_calories: calories,
          average_weight: startingWeight,
          tdee: tdee !== null ? tdee : calories,
          nutritiondetailid: detail[0].id,
        })
        .select();

      if (tdeeError) {
        logError(tdeeError);
        throw new Error(`Error inserting tdee: ${tdeeError.message}`);
      }

      return { success: true };
    } catch (error) {
      return { error: error.message };
    }
  }

  async getDiaryItems(date) {
    try {
      const { data, error } = await this.supabase
        .from("macrodetails")
        .select()
        .order("id", { ascending: false })
        .eq("date", date);

      if (error) {
        logError(error);
        throw new Error(`Error getting nutrition: ${error.message}`);
      }

      console.log(data);

      return data;
    } catch (error) {
      return { error: error.message };
    }
  }

  async insertDiaryItem(item) {
    try {
      const { data, error } = await this.supabase
        .from("macrodetails")
        .upsert(item, { onConflict: "id" })
        .select();

      if (error) {
        throw new Error(`Error inserting item: ${error.message}`);
      }

      return data;
    } catch (error) {
      return { error: error.message };
    }
  }

  async updateDiaryItem(item) {
    try {
      const { data, error } = await this.supabase
        .from("macrodetails")
        .update(item)
        .eq("id", item.id);

      if (error) {
        throw new Error(`Error updating item: ${error.message}`);
      }

      return data;
    } catch (error) {
      return { error: error.message };
    }
  }

  async deleteDiaryItem(id) {
    try {
      const { data, error } = await this.supabase
        .from("macrodetails")
        .delete()
        .eq("id", id)
        .select();

      if (error) {
        logError(error);
        throw new Error(`Error deleting item: ${error.message}`);
      }

      return data;
    } catch (error) {
      return { error: error.message };
    }
  }

  async insertFood(item) {
    const insertItem = {
      description: item.food,
      calories: item.calories,
      protein: item.protein,
      carbs: item.carbs,
      fats: item.fats,
      weight: item.weight ? item.weight : 1,
      type: item.type,
    };

    try {
      const { data, error } = await this.supabase
        .from("foods")
        .insert(insertItem)
        .select();

      if (error) {
        throw new Error(`Error adding item: ${error.message}`);
      }

      return data;
    } catch (error) {
      logError(error);
      return { error: error.message };
    }
  }

  async insertIngredients(ingredients, recipeId) {
    for (let i = 0; i < ingredients.length; i++) {
      ingredients[i].food_id = recipeId;
    }
    try {
      const { data, error } = await this.supabase
        .from("ingredients")
        .upsert(ingredients, { onConflict: "id" })
        .select();

      if (error) {
        throw new Error(`Error adding item: ${error.message}`);
      }

      return data;
    } catch (error) {
      logError(error);
      return { error: error.message };
    }
  }
  
  async saveSearchedFoodQuery(query) {
    try {
      await this.supabase
          .from("user_search")
          .insert({
            search_text: query
          })
    } catch (error) {
        logError(error);
    }
  }

  async searchMyFoods(query) {

    await this.saveSearchedFoodQuery(query);
    
    try {
      const { data, error } = await this.supabase
        .from("foods")
        .select()
        .ilike("description", `%${query}%`);

      if (error) {
        throw new Error(`Error searching foods: ${error.message}`);
      }

      console.log(data);

      return data;
    } catch (error) {
      return { error: error.message };
    }
  }

  async describeFood(prompt) {
    try {
      const request = {
        prompt: prompt,
      };

      let response = null;

      try {
        response = await apiClient.post("/api/food-input", request);
      } catch (error) {
        logError(error);
        return { error: "Error getting food description." };
      }

      const object = JSON.parse(response.data.message);
      return object;
    } catch (error) {
      logError(error);
      return { error: error.message };
    }
  }

  async calculateNewTDEEFromCheckIn(confirm) {
    try {
      const userId = await UserSettings.getUserId();

      //get todays date in the format yyyy-dd-mm

      const checkInDate = getTodaysDate();

      //get the date from the previous tdee

      const { data: tdee, error: tdeeError } = await this.supabase
        .from("tdeedetails")
        .select()
        .is("date_end", null);

      if (tdeeError) {
        logError(tdeeError);
        return { error: true, message: tdeeError.message};
      }

      //get the usermetrics from the stat date of the previous tdee until now.

      if (tdee.length === 0) {
        return { error: true, message: "No TDEE found." };
      }

      // if the consistancy is lower than 70% then return an error message.

      let consistancy = 0;

      const defaultError = "Your consistency is a little too low this week. Keep tracking as accurately as possible for updates to your nutrition";

      try {
        const { data, status } = await supabase.rpc("consistencypercentage", {
          _id: userId,
        });        

        if (status != 200) {
          return { error: true, message: defaultError };
        } else {
          if (data.length != 0) {
            consistancy = data;
          }
        }
      } catch (error) {
        return { error: true, message: defaultError };
      } finally {
        this.isLoading = false;
      }

      if (consistancy < 70) {
        return { error: true, message: defaultError };
      }

      const greaterThanDate = tdee[0].date_start;

      const {
        data: userMetrics,
        error: userMetricsError,
      } = await supabase
        .from("usermetrics")
        .select()
        .gte("date", greaterThanDate);

      if (userMetricsError || userMetrics.length === 0) {
        logError(userMetricsError);
        return { error: true, message: userMetricsError.message };
      }

      //calculate the average calories and weight

      const { data: metric, error: metricError } = await this.supabase
        .from("users")
        .select("ismetric")
        .eq("id", userId);

      if (metricError) {
        logError(metricError);
        return { error: true, message: metricError.message };
      }

      const isMetric = metric.length > 0 ? metric[0].ismetric : false;

      let totalCalories = 0;
      let calorieDays = 0;
      let totalWeight = 0;
      let weightDays = 0;
      const startingWeight = tdee[0].average_weight;

      for (let i = 0; i < userMetrics.length; i++) {
        if (userMetrics[i].weight && userMetrics[i].weight > 0) {
          totalWeight += userMetrics[i].weight;
          weightDays++;
        }
        if (userMetrics[i].calories && userMetrics[i].calories > 0) {
          totalCalories += userMetrics[i].calories;
          calorieDays++;
        }
      }

      const averageCalories =
        totalCalories && totalCalories > 0 ? totalCalories / calorieDays : null;
      const averageWeight =
        totalWeight && totalWeight > 0
          ? totalWeight / weightDays
          : startingWeight;

      const startingTdee = tdee[0].tdee;
      const caloriesPerUnit = isMetric ? 7700 : 3500;
      const change =
        ((startingWeight - averageWeight) * caloriesPerUnit) /
        userMetrics.length;

      const imediateTDEE = averageCalories + change;

      const rollingAverage = 6;

      const tdeeChange = (imediateTDEE - startingTdee) / rollingAverage;

      const newTDEE = tdee[0].tdee + tdeeChange;
      
      if (newTDEE < startingTdee - 400) {
        return {
          error: true,
          message:
            "Your new caluclated metabolism is less than expected. Keep tracking as accurately as possible.",
        };
      }

      if (newTDEE > 5000) {
        return {
          error: true,
          message:
            "Your new calculated metabolism is more than expected. Keep tracking as accurately as possible.",
        };
      }

      if (newTDEE < 1200) {
        return { warning: true, newTDEE: newTDEE.toFixed(0)};
      } 

      //Notify the user that their metabolism has changed, and would they like to update their macros to reflect this?
      if (!confirm) {
        return { pending: true, newTDEE: newTDEE.toFixed(0) };
      }

      const { error: insertTDEEError } = await this.supabase
        .from("tdeedetails")
        .insert({
          user_id: userId,
          date_start: checkInDate,
          average_calories: averageCalories,
          average_weight: averageWeight,
          tdee: newTDEE.toFixed(2),
        })
        .select();

      if (insertTDEEError) {
        logError(insertTDEEError);
        return { error: true, message: insertTDEEError.message };
      }

      if (tdee.length > 0) {
        const tdeeId = tdee[0].id;

        const { error: tdeeError } = await this.supabase
          .from("tdeedetails")
          .update({
            date_end: checkInDate,
          })
          .eq("id", tdeeId);

        if (tdeeError) {
          logError(tdeeError);
          return { error: true, message: tdeeError.message };
        }
      }

      const plan = await getCurrentPlan(userId);

      if (plan.error) {
        return { error: true, message: plan.message };
      }

      const newPlanResponse = await createNewPlan(
        averageWeight,
        plan.goal,
        plan.target_weight,
        plan.target_rate,
        plan.protein_factor,
        plan.dietary_style
      );

      if (!newPlanResponse) {
        return {
          error: true,
          message: "Something went wrong. Please try again later.",
        };
      } else {
        return true;
      }
    } catch (error) {
      return { error: true, message: error.message };
    }
  }
}

export default new Nutrition();
