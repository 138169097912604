class DateUtils {
  static convertUTCToLocalDate(date) {
    if (!date) return;

    return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  }

  static convertLocalDateToUTC(date) {
    if (!date) return;

    const utcDate = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );

    const returnDate =
      utcDate.getFullYear() +
      "-" +
      (utcDate.getMonth() + 1) +
      "-" +
      utcDate.getDate();

    return returnDate;
  }

  static formatLocalDate() {
    const currentDateTime = new Date();
    // Format the date time to "yyyy-mm-dd"
    return `${currentDateTime.getFullYear()}-${(currentDateTime.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${currentDateTime
      .getDate()
      .toString()
      .padStart(2, "0")}`;
  }
}

export default DateUtils;
