import { logError } from "../../errors/rollbar.js";
import apiClient from "../../service/apiClient.js";

/**
 * Send an email using SendGrid based on the form data, subject, and previous data
 * @param {*} formData - The form data
 * @param {string} subject - The subject of the email
 * @param {Array<T>} previousData - The previous data
 * @returns {Promise<boolean>} - Whether the email was sent successfully
 */
async function sendGridEmail(formData, subject, previousData) {
  const msg = {
    subject: subject,
    template: createEmailTemplate(formData, previousData),
    formdata: formData,
  };

  let response = null;

  try {
    response = await apiClient.post("/api/send-email", msg);
  } catch (error) {
    logError(error);
    return false;
  }

  if (response.status !== 200) {
    return false;
  }

  return true;
}

async function sendSelfCheckInEmail(formData, subject, previousData) {
  const msg = {
    subject: subject,
    template: createEmailTemplate(formData, previousData),
    formdata: formData,
  };

  let response = null;

  try {
    response = await apiClient.post("/api/create-self-checkin", msg);
  } catch (error) {
    logError(error);
    return false;
  }

  if (response.status !== 200) {
    return false;
  }

  return true;
}

function createEmailTemplate(formData, previousData) {
  return `
    <html>
    <body>
        <p>Name: ${formData.firstName},</p>
        <p>Email: ${formData.email},</p>
        <p>How do you feel about your accomplished goals over the last 7 days? - Where did you succeed?: ${
          formData.accomplishedGoals
        },</p>
        <p>What do you believe to be the obstacles you faced? What took priority? ${
          formData.obstaclesFaced
        },</p>
        <p>Activity for this week: ${formData.activity},</p>
        <p>Energy levels: ${formData.energy} out of 5,</p>
        <p>Stress levels: ${formData.stress} out of 5,</p>
        <p>Hunger levels: ${formData.hunger} out of 5,</p>
        <p>Days with feelings of emotional eating and cravings: ${
          formData.cravings
        } days,</p>
        <p>Digestion rating: ${formData.digestion} out of 5,</p>
        <p>Mental Health rating: ${formData.mentalHealth} out of 5,</p>
        <p>Waist Measurement: ${formData.waistMeasurement},</p>
        <p>Hips Measurement: ${formData.hipsMeasurement},</p>
        <p>Weight: ${formData.weight},</p>
        <p>Upcoming Events: ${formData.upcomingEvents},</p>
        <p>TDEE: ${formData.tdee},</p>
        ${
          previousData && previousData.length > 0
            ? `
        <table>
        <tr>
            <th>Date</th>
            <th>Energy</th>
            <th>Stress</th>
            <th>Hunger</th>
            <th>Cravings</th>
            <th>Digestion</th>
            <th>Mental</th>
            <th>Waist</th>
            <th>Hips</th>
            <th>Weight</th>
        </tr>
        ${previousData.map((data) => {
          return `
            <tr>
                <td>${data.created_at.substring(0, 10)}</td>
                <td>${data.energy}</td>
                <td>${data.stress}</td>
                <td>${data.hunger}</td>
                <td>${data.cravings}</td>
                <td>${data.digestion}</td>
                <td>${data.mentalHealth}</td>
                <td>${data.waistMeasurement}</td>
                <td>${data.hipsMeasurement}</td>
                <td>${data.weight}</td>
            </tr>
            `;
        })}
        <table>
        `
            : ""
        }
    </body>
    </html>
    `;
}

export { sendGridEmail, sendSelfCheckInEmail };
