import logError from "../../errors/rollbar.js";
import usdaClient from "@/service/usdaClient";
import supabase from "@/data/createClient";
import Nutrition from "../nutrition/nutrition";

export async function getFoods(brandedSearch, searchText) {

  await Nutrition.saveSearchedFoodQuery(searchText);
  
  try {
    let searchParams = [];

    searchParams.push("Foundation");
    searchParams.push("Survey (FNDDS)");
    searchParams.push("SR Legacy");

    if (brandedSearch) {
      searchParams.push("Branded");
    }

    searchParams = searchParams.join(",");

    const pageSize = 30;

    const response = await usdaClient.get(
      "/fdc/v1/foods/search",
      {
        params: {
          api_key: process.env.VUE_APP_USDA_API_KEY,
          query: searchText,
          pageSize: pageSize,
          pageNumber: 1,
          sortBy: "dataType.keyword",
          sortOrder: "asc",
          dataType: searchParams,
        },
      }
    );

    if (response.data.foods.length > 0) {
      return response.data.foods.map((item) => {
        const protein = item.foodNutrients.find(
          (nutrient) => nutrient.nutrientId === 1003
        );

        const carbs = item.foodNutrients.find(
          (nutrient) => nutrient.nutrientId === 1005
        );

        const fats = item.foodNutrients.find(
          (nutrient) => nutrient.nutrientId === 1004
        );

        const calories = item.foodNutrients.find(
          (nutrient) => nutrient.nutrientId === 1008
        );

        if (protein && protein.value < 0) protein.value = 0;
        if (carbs && carbs.value < 0) carbs.value = 0;
        if (fats && fats.value < 0) fats.value = 0;
        if (calories && calories.value < 0) calories.value = 0;

        let cal = 0;

        if (calories) {
          cal = calories.value;
        } else {
          if (protein && carbs && fats)
            cal = protein.value * 4 + carbs.value * 4 + fats.value * 9;
        }

        return {
          usdaid: item.fdcId ? item.fdcId : "",
          food: item.description ? item.description : "",
          protein: protein ? protein.value : "",
          carbs: carbs ? carbs.value : "",
          fats: fats ? fats.value : "",
          calories: cal,
          brand: item.brandOwner ? item.brandOwner : "",
          weight: 100,
          type: "g",
        };
      });
    } else {
      return [
        {
          food: "No results found",
          protein: "",
          carbs: "",
          fats: "",
          calories: "",
        },
      ];
    }
  } catch (error) {
    logError(error);
    return [
      {
        food: "No results found",
        protein: "",
        carbs: "",
        fats: "",
        calories: "",
      },
    ];
  }
}

export async function getFoodDetails(item, updateWeight = true) {
  try {
    const response = await usdaClient.get("/fdc/v1/foods", {
      params: {
        api_key: process.env.VUE_APP_USDA_API_KEY,
        fdcIds: item.usdaid,
        nutrients: [],
        format: "abridged",
      },
    });

    const foodPortions = [];
    
    const { data: dbPortions, error: portionsError } = await supabase
        .from("usda_food_portions")
        .select()
        .eq("fdc_id", item.usdaid);
    
    if (portionsError) {
     logError(portionsError);
    }
    
    if (dbPortions && dbPortions.length > 0) {
        for (let i = 0; i < dbPortions.length; i++) {
            const portion = dbPortions[i];
            
            if (portion.modifier !== "") {
              const portionToAdd = {
                amount: 1,
                gramWeight: portion.gram_weight,
                measureUnit: {
                  name: portion.modifier,
                },
              }
              foodPortions.push(portionToAdd);
            }
        }
    } 
    
    //searching and selecting items from the list use the same function, 
    // but one wants to update the weight, and the other does not.    
    if (updateWeight) {
      foodPortions.push({
        amount: 100,
        gramWeight: 100,
        measureUnit: {
          name: "g",
        },
      });
    } else {
        foodPortions.unshift({
            amount: 100,
            gramWeight: item.weight,
            measureUnit: {
            name: "g",
            },
        });
    }
    
    if (response.data.length > 0 && response.data[0].dataType === "Branded") {
      if (response.data[0].servingSize && response.data[0].servingSizeUnit) {
        foodPortions.push({
          amount: 1,
          gramWeight: response.data[0].servingSize,
          measureUnit: {
            name: "serving",
          },
        });
      }
    }

    if (response.data.length > 0 && response.data[0].foodPortions) {
      for (let i = 0; i < response.data[0].foodPortions.length; i++) {
        const portion = response.data[0].foodPortions[i];
        if (portion.gramWeight > 0) {
          if (response.data[0].dataType === "Survey (FNDDS)") {
            portion.measureUnit.name = portion.modifier;
          } else if (response.data[0].dataType === "SR Legacy") {
            portion.measureUnit.name = portion.modifier;
          }
          foodPortions.push(portion);
        }
      }
    }

    return {
      id: item.id,
      food: item.food,
      protein: item.protein,
      carbs: item.carbs,
      fats: item.fats,
      calories: item.calories,
      foodPortions: foodPortions,
      // update the weight if there are multiple portions and the weight is not being updated
      weight: foodPortions.length > 1 && !updateWeight ? foodPortions[0].gramWeight : 100,
      originalWeight: item.weight ? item.weight : "",
      usdaid: item.usdaid,
    };
  } catch (error) {
    logError(error);
  }
}
