const DietaryPreference = {
  Balanced: 1,
  Keto: 2,
  LowCarb: 3,
  Vegan: 4,
  LowFat: 5,
  HighProtein: 6,
};

const UserGroup = {
  TestUsers: 1,
  RegisteredUsers: 2,
  Admins: 3,
};

const ServingSize = {
  grams: 1,
  ml: 2,
  oz: 3,
  cups: 4,
  tbsp: 5,
  tsp: 6,
  each: 7,
};

export default { DietaryPreference, UserGroup, ServingSize };
