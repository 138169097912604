<template>
  <nav
    id="navbarBlur"
    class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl mt-3"
    v-bind="$attrs"
    data-scroll="true"
  >
    <div class="px-4 py-1 container-fluid">
      <div
        id="navbar"
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="isRTL ? 'px-0' : 'me-sm-4'"
      >
        <ul class="navbar-nav justify-content-start">
          <li class="nav-item d-xl-none ps-1 d-flex align-items-center">
            <a
              id="iconNavbarSidenav"
              class="p-0 nav-link text-body lh-1"
              @click="toggleSidebar"
            >
              <div id="divNavbarSidenav" class="sidenav-toggler-inner">
                <i id="iSideBarNav1" class="sidenav-toggler-line"></i>
                <i id="iSideBarNav2" class="sidenav-toggler-line"></i>
                <i id="iSideBarNav3" class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "Navbar",
  components: {},
  props: {
    minNav: {
      type: Function,
      default: () => {},
    },
    color: {
      type: String,
      default: "text-body",
    },
  },
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    ...mapState(["isRTL", "isAbsolute"]),
    currentRouteName() {
      return this.$route.name;
    },
  },
  created() {
    this.minNav;
  },

  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),

    toggleSidebar() {
      this.navbarMinimize();
    },
  },
};
</script>
