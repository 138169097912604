import { logError } from "../../errors/rollbar.js";
import supabase from "../createClient.js";
import UserSettings from "../user/userSettings.js";
import { calculateDietaryNutrition } from "../../helpers/nutritionCalculations.js";
import Nutrition from "../nutrition/nutrition.js";
import { getTodaysDate } from "../../helpers/dateUtils.js";

export async function getAverageWeight() {
  const userId = await UserSettings.getUserId();
  if (!userId) {
    console.error("No user id provided");
    return;
  }

  const { data: startingWeight } = await supabase
    .from("users")
    .select("startingweight")
    .eq("id", userId)
    .limit(1);

  try {
    const { data, status } = await supabase.rpc("get_last_4_weeks_weight", {
      _id: userId,
    });

    if (status != 200) {
      console.error("Error fetching data");
      return startingWeight;
    } else {
      if (data.length > 0) {
        //set this to two decimal places:
        const avgWeight = data[data.length - 1].avg_weight;
        return avgWeight.toFixed(2);
      } else {
        return startingWeight;
      }
    }
  } catch (error) {
    logError(error);
    console.error("Error fetching data:", error.message);
  }
}

export async function getCurrentMacros() {
  const userId = await UserSettings.getUserId();

  if (!userId) {
    console.error("No user id provided");
    return;
  }

  try {
    const { data, error } = await supabase
      .from("users")
      .select("nutritiondetailid")
      .eq("id", userId)
      .limit(1);

    if (error) {
      logError(error);
      return;
    }

    const nutritionDetailId = data[0].nutritiondetailid;

    const {
      data: nutritionDetailData,
      error: nutritionDetailError,
    } = await supabase
      .from("nutritiondetails")
      .select("calories, protein, carbs, fats")
      .eq("id", nutritionDetailId)
      .limit(1);

    if (nutritionDetailError || nutritionDetailData.length == 0) {
      logError(
        "There is no nutrition details for this user",
        nutritionDetailError
      );
      return;
    }

    return nutritionDetailData[0];
  } catch (error) {
    logError(error);
  }
}

export async function createNewPlan(
  currentAverageWeight,
  goal,
  targetWeight,
  targetRate,
  proteinFactor,
  dietaryStyle
) {
  const userId = await UserSettings.getUserId();

  if (!userId) {
    console.error("No user id provided");
    return;
  }

  //calorie calculation based on goal:
  //goal //0 == weightloss, 1 == maintanence 2 == weightgain
  const { calories, protein, carbs, fats } = await calculateNewMacros(
    targetRate,
    goal,
    dietaryStyle,
    currentAverageWeight,
    proteinFactor
  );

  //update any existing plans to mark them as complete:
  const todaysDate = getTodaysDate();

  try {
    //if any plans exists, mark them as complete:
    const { data: existingPlans } = await supabase
      .from("plans")
      .select()
      .is("end_date", null);

    if (existingPlans.length > 0) {
      for (let i = 0; i < existingPlans.length; i++) {
        await supabase
          .from("plans")
          .update({ end_date: todaysDate })
          .eq("id", existingPlans[i].id);
      }
    }

    //insert the new plan.
    const { error } = await supabase.from("plans").insert([
      {
        user_id: userId,
        target_rate: targetRate,
        target_weight: targetWeight,
        goal: goal,
        dietary_style: dietaryStyle,
        protein_factor: proteinFactor,
        calories: calories,
        start_date: todaysDate,
      },
    ]);

    if (error) {
      logError(error);
      return;
    }

    //save the new macros to the nutrition details table:
    const tdee = await getCurrentTDEE();

    const response = await Nutrition.saveMacros(
      userId,
      calories,
      protein,
      carbs,
      fats,
      dietaryStyle,
      goal,
      currentAverageWeight,
      tdee
    );

    if (!response) {
      logError("Error saving macros");
      return;
    }

    return true;
  } catch (error) {
    logError(error);
  }
}

export async function completeTwoWeekMaintanence() {
  //get the start date of the user
  const userId = await UserSettings.getUserId();

  if (!userId) {
    console.error("No user id provided");
    return;
  }

  const { data: user, error: userError } = await supabase
    .from("users")
    .select("datestart")
    .eq("id", userId);

  if (userError) {
    logError(userError);
    return;
  }

  //this is in format yyyy-dd-mm
  const startDate = user[0].datestart;

  //this is in the format of yyyy-mm-dd
  let todaysDate = getTodaysDate();

  //if two weeks have passed since the start date, return true, otherwise false.

  const diff = Math.abs(new Date(todaysDate) - new Date(startDate));

  const days = Math.floor(diff / 1000 / 60 / 60 / 24);

  if (days >= 14) {
    return true;
  } else {
    return false;
  }
}

export async function getCurrentTDEE() {
  const userId = await UserSettings.getUserId();

  if (!userId) {
    console.error("No user id provided");
    return;
  }

  try {
    const { data, error } = await supabase
      .from("tdeedetails")
      .select("tdee")
      .eq("user_id", userId)
      .is("date_end", null)
      .limit(1);

    if (error) {
      logError(error);
      return;
    }

    if (data.length > 0) {
      return data[0].tdee;
    } else {
      return null;
    }
  } catch (error) {
    logError(error);
  }
}

export async function calculateNewMacros(
  targetRate,
  goal,
  dietaryStyle,
  currentAverageWeight,
  proteinFactor
) {
  const tdee = await getCurrentTDEE();
  let newCalories = tdee;

  if (!tdee) {
    logError("No TDEE found");
    return;
  }

  if (goal === "0" || goal === 0) {
    const oneKgCalories = 7700;
    newCalories = tdee - (targetRate * oneKgCalories) / 7;

    if (newCalories < 1200) {
      newCalories = 1200;
    }
  } else if (goal === "2" || goal === 2) {
    const oneKgCalories = 7700;
    newCalories = tdee + (targetRate * oneKgCalories) / 7;
  }

  const { protein, carbs, fat } = calculateDietaryNutrition(
    dietaryStyle,
    newCalories,
    currentAverageWeight,
    proteinFactor
  );

  return { calories: newCalories, protein, carbs, fats: fat };
}

export async function getCurrentPlan(userId) {
  if (!userId) {
    logError("No user id provided");
    return { error: true, message: "No user id provided" };
  }

  try {
    const { data, error } = await supabase
      .from("plans")
      .select()
      .eq("user_id", userId)
      .is("end_date", null)
      .limit(1);

    if (error) {
      logError(error);
      return;
    }

    if (data.length > 0) {
      return data[0];
    } else {
      return null;
    }
  } catch (error) {
    logError(error);
  }
}
