import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueSweetalert2 from "vue-sweetalert2";
import MaterialDashboard from "./material-dashboard";
import RollbarPlugin from "./errors/rollbar.js";

import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faComment, faCamera, faX } from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faMagnifyingGlass, faComment, faCamera, faX);

const appInstance = createApp(App);
appInstance.component("FontAwesomeIcon", FontAwesomeIcon);
appInstance.use(store);
appInstance.use(router);
appInstance.use(VueSweetalert2);
appInstance.use(MaterialDashboard);
appInstance.use(RollbarPlugin);
appInstance.mount("#app");
