import Rollbar from "rollbar";
import config from "../../rollbar.config.js";
import dotenv from "dotenv";

dotenv.config();

config.accessToken = process.env.VUE_APP_ROLLBAR_ACCESS_TOKEN;

if (process.env.NODE_ENV === "development") {
  config.enabled = false;
}

const rollbar = new Rollbar(config);

export default {
  install(app) {
    app.config.errorHandler = (error, vm, info) => {
      rollbar.error(error, { vueComponent: vm, info });
      if (app.config.devtools) {
        console.error(error);
      }
    };
    app.provide("rollbar", rollbar);
  },
};

export function logError(error) {
  console.error(error);
  rollbar.error(error);
}
