// rollbar.config.js
export default {
  accessToken: process.env.ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.NODE_ENV === "development" ? "dev" : "production",
    client: {
      javascript: {
        code_version: "1.0.2",
      },
    },
    server: {
      root: "https://nutricoachme.com",
      branch: "main",
    },
  },
};
