import supabase from "../createClient";

async function submitForm(data) {
  try {
    const { data: form, error } = await supabase
      .from("forms")
      .upsert(
        {
          firstName: data.firstName,
          email: data.email,
          accomplishedGoals: data.accomplishedGoals,
          obstaclesFaced: data.obstaclesFaced,
          activity: data.activity,
          energy: data.energy,
          stress: data.stress,
          hunger: data.hunger,
          sleep: data.sleep,
          cravings: data.cravings,
          digestion: data.digestion,
          mentalHealth: data.mentalHealth,
          waistMeasurement: data.waistMeasurement,
          hipsMeasurement: data.hipsMeasurement,
          weight: data.weight,
          upcomingEvents: data.upcomingEvents,
          notes: data.notes,
          tdee: data.tdee,
        },
        { onConflict: "id" }
      )
      .select();
    if (error) {
      return { error: error };
    }
    return form;
  } catch (error) {
    return { error: error };
  }
}

async function getPreviousClientCheckIns(email) {
  const { data, error } = await supabase
    .from("forms")
    .select()
    .limit(5)
    .order("created_at", { ascending: false })
    .eq("email", email);
  if (error) {
    return { error: error };
  }

  return data;
}

export { submitForm, getPreviousClientCheckIns };
