function calculateTDEE(
  gender,
  age,
  weight,
  height,
  activityLevel,
  measurementSystem
) {
  //Mifflin-St Jeor BMR equation
  //(10 * weight) + (6.25 * height) – (5 * age) + gender
  //gender: male = 5, female = -161
  //Activity Level
  //1.2 = Sedentary (little or no exercise)
  //1.375 = Lightly Active (light exercise/sports 1-3 days/week)
  //1.55 = Moderately Active (moderate exercise/sports 3-5 days/week)
  //1.725 = Very Active (hard exercise/sports 6-7 days a week)
  //metric = 1, imperial = 2

  let bmr = 0;
  let tdee = 0;
  let metricWeight = measurementSystem === "1" ? weight / 2.205 : weight;
  let metricHeight = measurementSystem === "1" ? height * 2.54 : height;
  let genderFactor = gender === "1" ? -165 : 5;

  bmr = 10 * metricWeight + 6.25 * metricHeight - 5 * age + genderFactor;
  tdee = bmr * activityLevel;

  return roundToNearest5(tdee);
}

function calculateDietaryNutrition(
  dietaryStyle,
  tdee,
  weight = null,
  proteinFactor = null,
  isMetric = true
) {
  // 1: BALANCED
  // 2: KETO
  // 3: LOW CARB
  // 4: VEGAN
  // 5: LOW FAT
  // 6: HIGH PROTEIN

  // TYPE         PROTEIN	CARBS   FATS
  // BALANCED	    30%	    35%	    35%
  // KETO	        20%	    5%	    75%
  // LOW CARB	    25%	    10%	    65%
  // VEGAN	      15%	    55%	    30%
  // LOW FAT	    30%	    50%	    20%
  // HIGH PROTEIN	40%	    30%	    30%

  let protein = 0;
  let fat = 0;
  let carbs = 0;
  let calories = tdee;

  switch (dietaryStyle) {
    case "1":
      protein = (calories * 0.3) / 4;
      fat = (calories * 0.35) / 9;
      carbs = (calories * 0.35) / 4;
      break;
    case "2":
      protein = (calories * 0.2) / 4;
      fat = (calories * 0.75) / 9;
      carbs = (calories * 0.05) / 4;
      break;
    case "3":
      protein = (calories * 0.25) / 4;
      fat = (calories * 0.65) / 9;
      carbs = (calories * 0.1) / 4;
      break;
    case "4":
      protein = (calories * 0.15) / 4;
      fat = (calories * 0.3) / 9;
      carbs = (calories * 0.55) / 4;
      break;
    case "5":
      protein = (calories * 0.3) / 4;
      fat = (calories * 0.2) / 9;
      carbs = (calories * 0.5) / 4;
      break;
    case "6":
      protein = (calories * 0.4) / 4;
      fat = (calories * 0.3) / 9;
      carbs = (calories * 0.3) / 4;
      break;
    default:
      protein = (calories * 0.3) / 4;
      fat = (calories * 0.35) / 9;
      carbs = (calories * 0.35) / 4;
      break;
  }

  if (weight && proteinFactor) {
    const currentProtein = protein;
    const newProtein = weight * (isMetric ? 1 : 0.453592) * proteinFactor;

    let proteinDifference = newProtein - currentProtein;

    proteinDifference = proteinDifference * -1;

    const remainingCalories = proteinDifference * 4;

    let fatDifference = (remainingCalories * 0.5) / 9;
    const carbDifference = (remainingCalories * 0.5) / 4;

    carbs = carbs + carbDifference;

    // Ensure carbs do not go lower than 30g
    if (carbs < 30) {
      const excessCarbCalories = (30 - carbs) * 4; // Calculate the excess calories from carbs
      carbs = 30; // Set carbs to 30g
      fatDifference -= excessCarbCalories / 9; // Deduct the excess calories from fat
    }

    fat = fat + fatDifference;
    protein = newProtein;

    tdee = protein * 4 + fat * 9 + carbs * 4;
  }

  protein = roundToNearest5(protein);
  fat = roundToNearest5(fat);
  carbs = roundToNearest5(carbs);

  return { protein, fat, carbs, calories: tdee };
}

function roundToNearest5(x) {
  return Math.round(x / 5) * 5;
}

export { calculateTDEE, calculateDietaryNutrition };
